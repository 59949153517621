<div class="tds-container-fluid">
  <div class="tds-row">
    <h2 class="tds-headline-02">RENAVE</h2>
  </div>
  <div class="tds-row">
    <tds-inline-tabs id="tabsHeader" default-selected-index=0 mode-variant="primary" (tdsChange)="onTabChange($event)" style="margin-bottom: 1px;">
      <tds-inline-tab style="margin-left: 0;">
        <button>Veículos Pendentes de Entrada</button>
      </tds-inline-tab>
      <tds-inline-tab>
        <button>Veículos em Estoque</button>
      </tds-inline-tab>
      <tds-inline-tab>
        <button>Veículos Finalizados</button>
      </tds-inline-tab>
    </tds-inline-tabs>
  </div>
  <div class="tds-row" *ngIf="inlineTabIndex == 0">
    <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width (tdsFilter)="onFilterEvent($event)" (tdsSort)="onSortEvent($event)">
      <tds-table-toolbar filter>
        <div slot="end" class="tds-u-flex tds-u-align-items-center tds-u-h-100 tds-u-gap1" style="margin-left: 8px;">
          <tds-button variant="secondary" size="sm" title="Criar Mock de Chassi" (click)="TableAddChassiMockBtn()" *ngIf="environment">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="plus"></tds-icon>
          </tds-button>
        </div>
      </tds-table-toolbar>
      <tds-table-header>
        <tds-header-cell cell-key='nr_chassi' cell-value='Chassi' sortable></tds-header-cell>
        <tds-header-cell cell-key='fl_veiculo_acabado' cell-value='Carroceria' sortable></tds-header-cell>
        <tds-header-cell cell-key='dt_operacao' cell-value='Data de Operação' sortable></tds-header-cell>
        <tds-header-cell cell-key='cd_responsavel_operacao' cell-value='Operador' sortable></tds-header-cell>
        <tds-header-cell cell-key='ds_observacao' cell-value='Observação'></tds-header-cell>
        <tds-header-cell cell-key="actions"></tds-header-cell>
      </tds-table-header>
      <tds-table-body>
          <tds-table-body-row *ngFor="let data of dataSourcePendentes">
            <tds-body-cell cell-key='nr_chassi'>{{data.nr_chassi}}</tds-body-cell>
            <tds-body-cell cell-key='fl_veiculo_acabado'>{{data.fl_veiculo_acabado}}</tds-body-cell>
            <tds-body-cell cell-key='dt_operacao'>{{data.dt_operacao}}</tds-body-cell>
            <tds-body-cell cell-key='cd_responsavel_operacao'>{{data.cd_responsavel_operacao}}</tds-body-cell>
            <tds-body-cell cell-key='ds_observacao'>{{data.ds_observacao}}</tds-body-cell>
              <tds-body-cell cell-key="actions">
                <div class="action-buttons">
                    <tds-icon id="edit" name="settings" size="20px" title="Realizar Entrada de Estoque" (click)="TableEntryBtn(data)" style="cursor: pointer;"></tds-icon>
                </div>
            </tds-body-cell>
          </tds-table-body-row>
      </tds-table-body>
      <tds-table-footer id="tablePendenteFooter" pages="{{tablePendentePages}}" pagination (tdsPagination)="onPaginationEvent($event)"></tds-table-footer>
    </tds-table>
  </div>
  <div class="tds-row" *ngIf="inlineTabIndex == 1">
    <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width (tdsFilter)="onFilterEvent($event)" (tdsSort)="onSortEvent($event)">
      <tds-table-toolbar filter></tds-table-toolbar>
      <tds-table-header>
        <tds-header-cell cell-key='id_estoque' cell-value='Estoque Id' sortable></tds-header-cell>
        <tds-header-cell cell-key='nr_chassi' cell-value='Chassi' sortable></tds-header-cell>
        <tds-header-cell cell-key='fl_veiculo_acabado' cell-value='Carroceria' sortable></tds-header-cell>
        <tds-header-cell cell-key='dt_operacao' cell-value='Data de Operação' sortable></tds-header-cell>
        <tds-header-cell cell-key='cd_responsavel_operacao' cell-value='Operador'></tds-header-cell>
        <tds-header-cell cell-key='ds_observacao' cell-value='Observação'></tds-header-cell>
        <tds-header-cell cell-key="actions"></tds-header-cell>
      </tds-table-header>
      <tds-table-body>
          <tds-table-body-row *ngFor="let data of dataSourceConfirmados">
            <tds-body-cell cell-key='id_estoque'>{{data.id_estoque}}</tds-body-cell>
            <tds-body-cell cell-key='nr_chassi'>{{data.nr_chassi}}</tds-body-cell>
            <tds-body-cell cell-key='fl_veiculo_acabado'>{{data.fl_veiculo_acabado}}</tds-body-cell>
            <tds-body-cell cell-key='dt_operacao'>{{data.dt_operacao}}</tds-body-cell>
            <tds-body-cell cell-key='cd_responsavel_operacao'>{{data.cd_responsavel_operacao}}</tds-body-cell>
            <tds-body-cell cell-key='ds_observacao'>{{data.ds_observacao}}</tds-body-cell>
              <tds-body-cell cell-key="actions" style="display: flex;">
                <div class="action-buttons">
                    <tds-icon id="edit" name="cross" size="20px" title="Cancelar Entrada de Estoque" (click)="TableCancelEntryBtn(data)" style="cursor: pointer;"></tds-icon>
                </div>
                <div class="action-buttons" style="padding-left: 5px;">
                    <tds-icon id="edit" name="settings" size="20px" title="Realizar Saída de Estoque" (click)="TableExitBtn(data)" style="cursor: pointer;"></tds-icon>
                </div>
            </tds-body-cell>
          </tds-table-body-row>
      </tds-table-body>
      <tds-table-footer id="tableConfirmadoFooter" pages="{{tableConfirmadoPages}}" pagination (tdsPagination)="onPaginationEvent($event)"></tds-table-footer>
    </tds-table>
  </div>
  <div class="tds-row" *ngIf="inlineTabIndex == 2">
    <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width (tdsFilter)="onFilterEvent($event)" (tdsSort)="onSortEvent($event)">
      <tds-table-toolbar filter></tds-table-toolbar>
      <tds-table-header>
        <tds-header-cell cell-key='id_estoque' cell-value='Estoque Id' sortable></tds-header-cell>
        <tds-header-cell cell-key='nr_chassi' cell-value='Chassi' sortable></tds-header-cell>
        <tds-header-cell cell-key='fl_veiculo_acabado' cell-value='Carroceria' sortable></tds-header-cell>
        <tds-header-cell cell-key='dt_operacao' cell-value='Data de Operação' sortable></tds-header-cell>
        <tds-header-cell cell-key='cd_responsavel_operacao' cell-value='Operador'></tds-header-cell>
        <tds-header-cell cell-key='ds_observacao' cell-value='Observação'></tds-header-cell>
        <tds-header-cell cell-key="actions"></tds-header-cell>
      </tds-table-header>
      <tds-table-body>
          <tds-table-body-row *ngFor="let data of dataSourceFinalizados">
            <tds-body-cell cell-key='id_estoque'>{{data.id_estoque}}</tds-body-cell>
            <tds-body-cell cell-key='nr_chassi'>{{data.nr_chassi}}</tds-body-cell>
            <tds-body-cell cell-key='fl_veiculo_acabado'>{{data.fl_veiculo_acabado}}</tds-body-cell>
            <tds-body-cell cell-key='dt_operacao'>{{data.dt_operacao}}</tds-body-cell>
            <tds-body-cell cell-key='cd_responsavel_operacao'>{{data.cd_responsavel_operacao}}</tds-body-cell>
            <tds-body-cell cell-key='ds_observacao'>{{data.ds_observacao}}</tds-body-cell>
              <tds-body-cell cell-key="actions" style="display: flex;">
                <div class="action-buttons">
                    <tds-icon id="edit" name="cross" size="20px" title="Cancelar Saída de Estoque" (click)="TableCancelExitBtn(data)" style="cursor: pointer;"></tds-icon>
                </div>
                <div class="action-buttons" style="padding-left: 5px;">
                    <tds-icon id="edit" name="document_pdf" size="20px" title="Baixar Documento" (click)="TablePDFBtn(data)" style="cursor: pointer;"></tds-icon>
                </div>
            </tds-body-cell>
          </tds-table-body-row>
      </tds-table-body>
      <tds-table-footer id="tableFinalizadoFooter" pages="{{tableFinalizadoPages}}" pagination (tdsPagination)="onPaginationEvent($event)"></tds-table-footer>
    </tds-table>
  </div>
</div>
