import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { filter, map, Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/service/shared.service';
import { RenaService } from './service/rena.service';
import { HeaderService } from './service/header.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements AfterViewInit {

  private subscriptionList: Subscription[] = [];
  title = 'rena-frontend';

  constructor(public authService: AuthService,
              public shared: SharedService,
              public headerService: HeaderService,
              public renaService: RenaService,
              private renderer: Renderer2){
    this.renderer.setAttribute(document.body, 'data-env', environment.envFlag);
    
    this.subscriptionList.push(
      this.authService.checkAuthentication().pipe(
        filter((value) => !!value),
        map(() => this.authService.getUserData()),
        switchMap((user) => this.authService.getUserRoles(user!.wam_user.global))
      ).subscribe((response) => {
        if (response.success) {
          if (typeof(response.data) === "string") {
            this.shared.errorAlert(String(response.data).split("|")[0], String(response.data).split("|")[1]);
          }
          else
          {
            let roles:Array<string> = response.data.role.map(role => role.ds_role);
            this.headerService.setMenus(roles);
          }
        }
      })
    );
  };

  ngAfterViewInit(){
    setTimeout(() => {
    let footer = document.getElementById('footerid');
    const shadowRoot = footer!.shadowRoot;
    shadowRoot!.innerHTML = `
        </style>
        <footer>
            <div class="footer-main">
                <div class="footer-main-top" style="display:none"></div>
                <div class="footer-main-bottom">
                    <small class="copyright">Copyright © 2024 Scania</small>
                    <div class="brand">
                        <p>Scania</p>
                    </div>
                </div>
            </div>
        </footer>
    `;}, 1000);
  }
}
