import { Component, OnInit } from '@angular/core';
import { IApiEnvelope, IAplicacao, ICategoria, IChassi, ICombustivel, IMarca, IMarcaModelo, IPaginatedFilters, ISegmento, ITracao } from 'src/app/auth/interfaces';
import { RenaService } from 'src/app/service/rena.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-marca-modelo',
  templateUrl: './marca-modelo.component.html',
  styleUrls: ['./marca-modelo.component.css']
})

export class MarcaModeloComponent implements OnInit {
  public tableData:IMarcaModelo[] = [];
  private aplicacaoArr:IAplicacao[] = [];
  private categoriaArr:ICategoria[] = [];
  private chassiArr:IChassi[] = [];
  private combustivelArr:ICombustivel[] = [];
  private segmentoArr:ISegmento[] = [];
  private tracaoArr:ITracao[] = [];
  private marcaArr:IMarca[] = [];
  private filtros:IPaginatedFilters = { paginationValue: '1', paginationSize: '10', columnKey: '', sortingDirection: '', query: ''};
  public tablePages:number = 1;

  constructor(private renaService: RenaService) {
  }

  ngOnInit() {
    this.getRenavamMarcaModeloTableDataPaginated(this.filtros);
    this.loadModalDropdownData();
  }

  onPaginationEvent(event: any) {
    let filtros = this.filtros;
    filtros.paginationValue = event.detail.paginationValue;
    this.getRenavamMarcaModeloTableDataPaginated(filtros);
  }

  onSortEvent(event: any) {
    let filtros = this.filtros;
    filtros.columnKey = event.detail.columnKey;
    filtros.paginationValue = '1';
    (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
    filtros.sortingDirection = event.detail.sortingDirection;
    this.getRenavamMarcaModeloTableDataPaginated(filtros);
  }

  onFilterEvent(event: any) {
    let filtros = this.filtros;
    filtros.query = event.detail.query;
    filtros.paginationValue = '1';
    (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
    this.getRenavamMarcaModeloTableDataPaginated(filtros);
  }
  
  TableActionBtn(selectedModel:IMarcaModelo){
    Swal.fire({
      title: `${selectedModel.cd_modelo} - ${selectedModel.ds_modelo}`,
      width: 600,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#16417f',
      html: `
      <div class="tds-container">
        <div class="tds-row" style="margin-bottom:25px">
          <div class="tds-col-sm-6">
            <div style="width:208px">
              <div class="tds-dropdown">
                <tds-text-field id="codigo-modelo" label="Código" label-position="outside" read-only>
                </tds-text-field> 
              </div>
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px">
              <div class="tds-dropdown">
                <tds-text-field id="descricao-modelo" label="Modelo" label-position="outside">
                </tds-text-field> 
              </div>
            </div>
          </div>
        </div>
        <div class="tds-row" style="margin-bottom:25px">
          <div class="tds-col-sm-6">
            <div style="width:208px">
              <div class="tds-dropdown revert-select">
                <div class="text-field-label-outside sc-tds-text-field">
                  <div class="sc-tds-text-field">
                    Marca
                  </div>
                </div>
                <select name="nativeDropdown" id="marca-modelo" class="text-field-input text-field-input-lg sc-tds-text-field">
                  <option value="" disabled selected>Selecione</option>
                </select>
              </div>
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px">
              <div class="tds-dropdown revert-select">
                <div class="text-field-label-outside sc-tds-text-field">
                  <div class="sc-tds-text-field">
                    Categoria
                  </div>
                </div>
                <select name="nativeDropdown" id="categoria-modelo" class="text-field-input text-field-input-lg sc-tds-text-field">
                  <option value="" disabled selected>Selecione</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="tds-row" style="margin-bottom:25px">
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <div class="tds-dropdown revert-select">
                <div class="text-field-label-outside sc-tds-text-field">
                  <div class="sc-tds-text-field">
                    Segmento
                  </div>
                </div>
                <select name="nativeDropdown" id="tipo-segmento" class="text-field-input text-field-input-lg sc-tds-text-field">
                  <option value="" disabled selected>Selecione</option>
                </select>
              </div>
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px">
              <div class="tds-dropdown revert-select">
                <div class="text-field-label-outside sc-tds-text-field">
                  <div class="sc-tds-text-field">
                    Combustivel
                  </div>
                </div>
                <select name="nativeDropdown" id="tipo-combustivel" class="text-field-input text-field-input-lg sc-tds-text-field">
                  <option value="" disabled selected>Selecione</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="tds-row" style="margin-bottom:25px">
          <div class="tds-col-sm-6">
            <div style="width:208px">
              <div class="tds-dropdown revert-select">
                <div class="text-field-label-outside sc-tds-text-field">
                  <div class="sc-tds-text-field">
                    Chassi
                  </div>
                </div>
                <select name="nativeDropdown" id="tipo-chassi" class="text-field-input text-field-input-lg sc-tds-text-field">
                  <option value="" disabled selected>Selecione</option>
                </select>
              </div>
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <div class="tds-dropdown revert-select">
                <div class="text-field-label-outside sc-tds-text-field">
                  <div class="sc-tds-text-field">
                    Tração
                  </div>
                </div>
                <select name="nativeDropdown" id="tipo-tracao" class="text-field-input text-field-input-lg sc-tds-text-field">
                  <option value="" disabled selected>Selecione</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="tds-row" style="margin-bottom:25px">
          <div class="tds-col-sm-6">
            <div style="width:208px">
              <tds-text-field id="pbt" label="PBT" label-position="outside">
              </tds-text-field> 
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <tds-text-field id="cmt" label="CMT" label-position="outside">
              </tds-text-field> 
            </div>
          </div>
        </div>
        <div class="tds-row">
          <div class="tds-col-sm-6">
            <div style="width:208px">
              <div class="tds-dropdown revert-select">
                <div class="text-field-label-outside sc-tds-text-field">
                  <div class="sc-tds-text-field">
                    Aplicações
                  </div>
                </div>
                <select name="nativeDropdown" id="modelo-aplicacao" multiple class="text-field-input text-field-input-lg sc-tds-text-field">
                  <option value="" disabled selected>Selecione</option>
                </select>
              </div>
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <tds-text-field id="potencia" label="Potência (cv/kW)" label-position="outside">
              </tds-text-field> 
            </div>
          </div>
        </div>
        <div id="conditional-div" class="tds-row">
          <div id="comprimento-div" class="tds-col-sm-6" style="display:none">
            <div style="width:208px">
              <tds-text-field id="comprimento" label="Comprimento (m)" label-position="outside">
              </tds-text-field> 
            </div>
          </div>
          <div id="bateria-div" class="tds-col-sm-6" style="display:none">
            <div style="width:208px"> 
              <tds-text-field id="bateria" label="Bateria (kWh)" label-position="outside">
              </tds-text-field> 
            </div>
          </div>
        </div>
      </div>
      `,
      willOpen: () => {
        let codigo = (document.getElementById('codigo-modelo') as HTMLInputElement);
        let descricao = (document.getElementById('descricao-modelo') as HTMLInputElement);
        let aplicacao = (document.getElementById('modelo-aplicacao') as HTMLSelectElement);
        let categoria = (document.getElementById('categoria-modelo') as HTMLSelectElement);
        let chassi = (document.getElementById('tipo-chassi') as HTMLSelectElement);
        let combustivel = (document.getElementById('tipo-combustivel') as HTMLSelectElement);
        let segmento = (document.getElementById('tipo-segmento') as HTMLSelectElement);
        let tracao = (document.getElementById('tipo-tracao') as HTMLSelectElement);
        let marca = (document.getElementById('marca-modelo') as HTMLSelectElement); 
        let pbt = (document.getElementById('pbt') as HTMLInputElement);
        let cmt = (document.getElementById('cmt') as HTMLInputElement);
        let potencia = (document.getElementById('potencia') as HTMLInputElement);
        let comprimento = (document.getElementById('comprimento') as HTMLInputElement);
        let bateria = (document.getElementById('bateria') as HTMLInputElement);

        this.aplicacaoArr.forEach(a => aplicacao.add(new Option(a.ds_aplicacao, a.id_aplicacao.toString())));
        this.categoriaArr.forEach(c => categoria.add(new Option(c.ds_categoria, c.cd_categoria)));
        this.chassiArr.forEach(c => chassi.add(new Option(c.ds_chassi, c.id_chassi.toString())));
        this.combustivelArr.forEach(c => combustivel.add(new Option(c.ds_combustivel, c.id_combustivel.toString())));
        this.segmentoArr.forEach(s => segmento.add(new Option(s.ds_segmento, s.id_segmento.toString())));
        this.tracaoArr.forEach(t => tracao.add(new Option(t.ds_tracao, t.id_tracao.toString())));
        this.marcaArr.forEach(m => marca.add(new Option(m.ds_marca, m.id_marca.toString())));

        this.renaService.getMarcaModelo(`?cd_modelo=${selectedModel.cd_modelo}`)
        .subscribe(response => {
          if (response.success) {
            let modelo:IMarcaModelo = response.data[0];
            
            Array.from(aplicacao.options).forEach((option) => {
              if (modelo.cd_aplicacoes?.includes(option.value)) {
                option.selected = true;
              }
            });

            codigo.value = modelo.cd_modelo.toString();
            descricao.value = modelo.ds_modelo;
            categoria.value = modelo.cd_categoria!;
            chassi.value = modelo.id_chassi?.toString()!;
            combustivel.value = modelo.id_combustivel?.toString()!;
            segmento.value = modelo.id_segmento?.toString()!;
            tracao.value = modelo.id_tracao?.toString()!;
            marca.value = modelo.id_marca?.toString()!;
            pbt.value = modelo.ds_pbt?.toString()!;
            cmt.value = modelo.ds_cmt?.toString()!;
            potencia.value = modelo.ds_potencia?.toString()!;
            comprimento.value = modelo.vl_comprimento?.toString()!;
            bateria.value = modelo.vl_bateria?.toString()!;
          }
        });
      },
      didOpen: () => {
        let conditionalDiv = (document.getElementById('conditional-div') as HTMLDivElement);
        let comprimentoDiv = (document.getElementById('comprimento-div') as HTMLDivElement);
        let bateriaDiv = (document.getElementById('bateria-div') as HTMLDivElement);
        let combustivel = (document.getElementById('tipo-combustivel') as HTMLSelectElement);
        let bateria = (document.getElementById('bateria') as HTMLInputElement);

        if (selectedModel.ds_carroceria == "Ônibus") {
          comprimentoDiv.style.display = "block";
          conditionalDiv.style.marginTop = "25px";
        }

        if (selectedModel.ds_combustivel == "Eletrico") {
          bateriaDiv.style.display = "block";
          conditionalDiv.style.marginTop = "25px";
        }

        combustivel.addEventListener('change', function() {
          if (this.value == "3") {
            conditionalDiv.style.marginTop = "25px";
            bateriaDiv.style.display = "block";
          } else {
            conditionalDiv.style.marginTop = "";
            bateriaDiv.style.display = "none";
            bateria.value = "";
          }
        });
      },
      preConfirm: () => {
        let aplicacao = (document.getElementById('modelo-aplicacao') as HTMLSelectElement);
        let aplicacaoSelected = aplicacao.querySelectorAll("option:checked");
        // @ts-ignore
        let arrAplic = Array.from(aplicacaoSelected).map(el => el.value);
        arrAplic.shift();

        let descricao = (document.getElementById('descricao-modelo') as HTMLInputElement);
        let categoria = (document.getElementById('categoria-modelo') as HTMLSelectElement);
        let chassi = (document.getElementById('tipo-chassi') as HTMLSelectElement);
        let combustivel = (document.getElementById('tipo-combustivel') as HTMLSelectElement);
        let segmento = (document.getElementById('tipo-segmento') as HTMLSelectElement);
        let tracao = (document.getElementById('tipo-tracao') as HTMLSelectElement);
        let marca = (document.getElementById('marca-modelo') as HTMLSelectElement);
        let pbt = (document.getElementById('pbt') as HTMLInputElement);
        let cmt = (document.getElementById('cmt') as HTMLInputElement);
        let potencia = (document.getElementById('potencia') as HTMLInputElement);
        let comprimento = (document.getElementById('comprimento') as HTMLInputElement);
        let bateria = (document.getElementById('bateria') as HTMLInputElement);

        let modeloUpdate:any = {
          cd_modelo: selectedModel.cd_modelo,
          ds_modelo: descricao.value,
          id_marca: parseInt(marca.value),
          cd_categoria: categoria.value,
          id_chassi: parseInt(chassi.value),
          id_combustivel: parseInt(combustivel.value),
          id_segmento: parseInt(segmento.value),
          id_tracao: parseInt(tracao.value),
          ds_pbt: pbt.value,
          ds_cmt: cmt.value,
          ds_potencia: potencia.value,
          cd_aplicacoes: arrAplic,
          vl_comprimento: parseInt(comprimento.value),
          vl_bateria: parseInt(bateria.value)
        }

        this.renaService.postMarcaModelo(modeloUpdate)
        .subscribe((response:IApiEnvelope) => {
          if (response.success) {
            Swal.fire({
              icon: 'success',
              title: 'Veículo alterado.',
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });

            this.getRenavamMarcaModeloTableDataPaginated(this.filtros);
          } else {
            Swal.fire({
              icon: 'error',
              title: response.error.titulo,
              text: response.error.mensagemParaUsuarioFinal,
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
          }
        });
      }
    });
  }

  getRenavamMarcaModeloTableDataPaginated(filtros: IPaginatedFilters){
    this.renaService.getMarcaModeloTableDataPaginated(filtros)
    .subscribe(response => {
      this.tableData = response.data;
      this.tablePages = response.totalPages;
    })
  }

  TableUploadDocBtn(){
    Swal.fire({
      title: 'Cadastrar novos modelos',
      input: 'file',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#16417f',
      inputAttributes: {
        'accept': 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'aria-label': 'Upload monthly vehicle registrations'
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const file: File = result.value;
        if (file) {
          const formData = new FormData();
          formData.append('file', file);

          this.renaService.uploadExcel(formData)
            .subscribe(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Upload efetuado.',
                  toast: true,
                  position: 'bottom-end',
                  timer: 10000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                });
                this.getRenavamMarcaModeloTableDataPaginated(this.filtros);
            });
        }
      }
    });
  }

  TableUpdateDocBtn(){
    Swal.fire({
      title: 'Atualizar modelos',
      input: 'file',
      footer: '<tds-button type="button" id="update-doc-btn" variant="secundary" size="sm" text="Download de arquivo base"><tds-icon slot="icon" size="16px" name="document_xls"></tds-icon></tds-button> ',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#16417f',
      inputAttributes: {
        'accept': 'application/vnd.ms-excel.sheet.macroEnabled.12',
        'aria-label': 'Update vehicle models'
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const file: File = result.value;
        if (file) {
          const formData = new FormData();
          formData.append('file', file);
          
          this.renaService.updateExcel(formData)
          .subscribe((response:IApiEnvelope) => {
            if (response == null || typeof(response.data) == 'object') {
              Swal.fire({
                icon: 'success',
                title: 'Atualização efetuada.',
                toast: true,
                position: 'bottom-end',
                timer: 10000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
              });
            } 
            else if (typeof(response.data) == 'string') {
              let data:string = response.data;
              
              Swal.fire({
                icon: data.split('|')[0].startsWith("Erro") ? 'error' : 'warning',
                title: data.split('|')[0],
                text: data.split('|')[1],
                toast: true,
                position: 'bottom-end',
                timer: 10000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
              })
            }
          });
        }
      }
    });
    document.getElementById('update-doc-btn')?.addEventListener('click', () => {
      this.renaService.downloadBaseExcel()
      .subscribe((response: Blob) => {
        const blob = new Blob([response], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' });

        saveAs(blob, 'MarcaModelo_Update.xlsm');
      });
    });
  }

  TableDownloadDocBtn(){
    this.renaService.downloadExcel()
      .subscribe((response: Blob) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, 'MarcaModelo.xlsx');
      });
  }

  TableSendQuestBtn(){
    Swal.fire({
      title: 'Enviar lista de modelos atualizados (INDECX)',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#16417f'
    }).then((result) => {
      if (result.isConfirmed) {
        this.renaService.sendQuest().subscribe((response:IApiEnvelope) => {
          if (response == null || typeof(response.data) == 'object') {
            Swal.fire({
              icon: 'success',
              title: 'Email enviado.',
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
          } 
          else if (typeof(response.data) == 'string') {
            let data:string = response.data;
            
            Swal.fire({
              icon: data.split('|')[0].startsWith("Erro") ? 'error' : 'warning',
              title: data.split('|')[0],
              text: data.split('|')[1],
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            })
          }
        });
      }
    });
  }

  loadModalDropdownData(){
    this.renaService.getAplicacao()
    .subscribe(response => {
      this.aplicacaoArr = response.data;
    });

    this.renaService.getCategoria()
    .subscribe(response => {
      this.categoriaArr = response.data;
    });

    this.renaService.getChassi()
    .subscribe(response => {
      this.chassiArr = response.data;
    });

    this.renaService.getCombustivel()
    .subscribe(response => {
      this.combustivelArr = response.data;
    });

    this.renaService.getSegmento()
    .subscribe(response => {
      this.segmentoArr = response.data;
    });

    this.renaService.getTracao()
    .subscribe(response => {
      this.tracaoArr = response.data;
    });

    this.renaService.getMarca()
    .subscribe(response => {
      this.marcaArr = response.data;
    });
  }
}
