<div class="tds-container-fluid">
  <div class="tds-row">
    <h2 class="tds-headline-02">Códigos</h2>
  </div>
  <div class="tds-row">
    <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width (tdsFilter)="onFilterEvent($event)" (tdsSort)="onSortEvent($event)">
      <tds-table-toolbar tableTitle="{{tableNames[tableNum]}}" filter>
        <div slot="end" class="tds-u-flex tds-u-align-items-center tds-u-h-100 tds-u-gap1" style="margin-left: 8px;">
          <tds-button variant="secondary" size="sm" title="Incluir Cor" (click)="TableAddCorBtn()" *ngIf="tableNum == 1">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="plus"></tds-icon>
          </tds-button>
          <tds-button variant="secondary" size="sm" title="COR" (click)="onTableChange(1)">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="pin"></tds-icon>
          </tds-button>
          <tds-button variant="secondary" size="sm" title="COMBUSTÍVEL" (click)="onTableChange(6)">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="fuel_gauge"></tds-icon>
          </tds-button>
          <tds-button variant="secondary" size="sm" title="CARROCERIAS" (click)="onTableChange(7)">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="truck"></tds-icon>
          </tds-button>
          <tds-button variant="secondary" size="sm" title="RESTRIÇÕES" (click)="onTableChange(8)">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="profile_inactive"></tds-icon>
          </tds-button>
        </div>
      </tds-table-toolbar>
      <tds-table-header>
        <tds-header-cell cell-key='coD_ORIGINAL' cell-value='Código SCANIA' sortable></tds-header-cell>
        <tds-header-cell cell-key='coD_RENAVAM' cell-value='Código RENAVAM' sortable></tds-header-cell>
        <tds-header-cell cell-key='denoM_CURTA' cell-value='Descrição' sortable></tds-header-cell>
      </tds-table-header>
      <tds-table-body>
          <tds-table-body-row *ngFor="let data of tableData">
              <tds-body-cell cell-key="coD_ORIGINAL">{{data.coD_ORIGINAL}}</tds-body-cell>
              <tds-body-cell cell-key="coD_RENAVAM">{{data.coD_RENAVAM}}</tds-body-cell>
              <tds-body-cell cell-key="denoM_CURTA">{{data.denoM_CURTA}}</tds-body-cell>
          </tds-table-body-row>
      </tds-table-body>
      <tds-table-footer id="tableFooter" pages="{{tablePages}}" pagination (tdsPagination)="onPaginationEvent($event)"></tds-table-footer>
    </tds-table>
  </div>
</div>
