import { AuthService } from './../../auth/auth.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.css']
})
export class LoginCallbackComponent  {

  private subscription: Subscription[] = [];

  constructor(
      private activatedRoute: ActivatedRoute,
      private authService: AuthService,
      private router: Router,
  ) {
      this.subscription.push(
          this.activatedRoute.queryParams
              .pipe(
                  map((params) => params['code']),
                  switchMap((code) => this.authService.getToken(code)),
                  map((token) => this.authService.setToken(token))
              )
              .subscribe(() => this.router.navigate(['/Home']))
      );
  }

  ngOnDestroy(): void {
      this.subscription.forEach((value) => value.unsubscribe());
  }
}
