import { Component, OnDestroy } from '@angular/core';
import { filter, Subscription, tap, map, Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { HeaderService } from '../../service/header.service';
import { IUser } from './../../auth/interfaces';
import { SpinnerService } from 'src/app/service/spinner.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnDestroy {
  private subscriptionList: Subscription[] = [];
  public isUserAuthenticated: boolean = false;
  public userData: IUser | null = null;
  public userIsAdmin$: Subject<boolean> = new Subject<boolean>();
  public userHasRenavamAccess$: Subject<boolean> = new Subject<boolean>();
  public userHasRenaveAccess$: Subject<boolean> = new Subject<boolean>();
  
  loadingState = false;

  constructor( public authService: AuthService, private headerService: HeaderService, private spinnerService: SpinnerService )
  {
    this.subscriptionList.push(
      this.authService.checkAuthentication().pipe(
        tap((value) => this.isUserAuthenticated = value),
        filter((value) => !!value),
        map(() => this.authService.getUserData())).subscribe((user) => {
          this.userData = user;
        }),
      this.spinnerService.getLoadingState().subscribe((state) => {
        this.loadingState = state;
      }),
      this.headerService.menus$.pipe(
        map(menus => menus.includes('Admin'))
      ).subscribe(hasAccess  => {
        this.userIsAdmin$.next(hasAccess );
      }),
      this.headerService.menus$.pipe(
        map(menus => menus.includes('Renavam'))
      ).subscribe(hasAccess  => {
        this.userHasRenavamAccess$.next(hasAccess );
      }),
      this.headerService.menus$.pipe(
        map(menus => menus.includes('Renave'))
      ).subscribe(hasAccess  => {
        this.userHasRenaveAccess$.next(hasAccess );
      })
    );
  }
  
  userLogout(): void {
    this.headerService.clearMenus();
    this.authService.userLogout();
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
  }
}
