import { Component, OnInit } from '@angular/core';
import { IApiEnvelope, ICodigosRenavam, IPaginatedFilters } from 'src/app/auth/interfaces';
import { RenaService } from 'src/app/service/rena.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-codigos',
  templateUrl: './codigos.component.html',
  styleUrls: ['./codigos.component.css']
})

export class CodigosComponent implements OnInit {
  public tableData:ICodigosRenavam[] = [];
  public tablePages:number = 1;
  private username: string = (document.getElementById('username') as HTMLTdsHeaderDropdownListUserElement).imgAlt;
  private filtros:IPaginatedFilters = { paginationValue: '1', paginationSize: '10', columnKey: '', sortingDirection: '', query: ''};
  public tableNames:string[] = ["", "Cores", "Tipos de Compradores", "Tipos de Veículos", "Tipos de Vendas", "Páis de Origem", "Combustíveis", "Carrocerias", "Restrições"];
  public tableNum:number = 1;

  constructor(private renaService: RenaService) {
  }

  ngOnInit() {
    this.GetCodigosRenavamPaginated(1);
  }

  onPaginationEvent(event: any) {
    let filtros = this.filtros;
    filtros.paginationValue = event.detail.paginationValue;
    this.GetCodigosRenavamPaginated(this.tableNum);
  }

  onSortEvent(event: any) {
    let filtros = this.filtros;
    filtros.columnKey = event.detail.columnKey;
    filtros.paginationValue = '1';
    (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
    filtros.sortingDirection = event.detail.sortingDirection;
    this.GetCodigosRenavamPaginated(this.tableNum);
  }

  onFilterEvent(event: any) {
    let filtros = this.filtros;
    filtros.query = event.detail.query;
    filtros.paginationValue = '1';
    (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
    this.GetCodigosRenavamPaginated(this.tableNum);
  }

  TableAddCorBtn()
  {
    Swal.fire({
      title: 'Incluir variante de Cor',
      showCancelButton: true,
      width: 600,
      allowOutsideClick: false,
      confirmButtonColor: '#16417f',
      html: `
      <div class="tds-container">
        <div class="tds-row">
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <tds-text-field type="text" id="cor-scania" label="Código SCANIA" label-position="outside">
              </tds-text-field>
            </div>
          </div>
          <div class="tds-col-sm-6">
            <div style="width:208px"> 
              <div class="tds-dropdown revert-select">
                <div class="text-field-label-outside sc-tds-text-field">
                  <div class="sc-tds-text-field">
                    Descrição
                  </div>
                </div>
                <select name="nativeDropdown" id="cor-desc" class="text-field-input text-field-input-lg sc-tds-text-field" style="height:55px">
                  <option value="" disabled selected>Selecione</option>
                  <option value="1">AMARELA</option>
                  <option value="2">AZUL</option>
                  <option value="3">BEGE</option>
                  <option value="4">BRANCA</option>
                  <option value="5">CINZA</option>
                  <option value="6">DOURADA</option>
                  <option value="7">AMARELA</option>
                  <option value="8">LARANJA</option>
                  <option value="9">MARROM</option>
                  <option value="10">PRATA</option>
                  <option value="11">PRETA</option>
                  <option value="12">ROSA</option>
                  <option value="13">ROXA</option>
                  <option value="14">VERDE</option>
                  <option value="15">VERMELHA</option>
                  <option value="16">FANTASIA</option>
                </select>
              </div>
              <div id="cor-desc-error" class="sc-tds-text-field text-field-helper-error-state" style="color:var(--tds-text-field-helper-error);font:var(--tds-detail-05);padding-top:4px;display:none">
                <tds-icon class="sc-tds-text-field hydrated" name="error" size="16px"></tds-icon>obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>`,
      preConfirm: () => {
        let cod_original = (document.getElementById('cor-scania') as HTMLTdsTextFieldElement);
        let cod_renavam = (document.getElementById('cor-desc') as HTMLSelectElement);
        let corDescError = (document.getElementById('cor-desc-error') as HTMLDivElement);
        
        limpaValidacao();
        function limpaValidacao() {
          cod_original.state = "default";
          cod_original.helper= "";
          cod_renavam.style.borderBottom = "";
          corDescError.style.display = "none";
        }

        let hasError = RealizaValidacao();
        function RealizaValidacao() {
          let hasError:boolean = false;

          if (cod_original.value == '') {
            cod_original.state = "error";
            cod_original.helper= "obrigatório";
            hasError = true;
          }
          if (cod_renavam.value == '') {
            cod_renavam.style.borderBottom = "1px solid red";
            corDescError.style.removeProperty("display");
            hasError = true;
          }
          return hasError;
        }

        if (hasError) {
          Swal.showValidationMessage(
            'Preencher campos obrigatórios.'
          );
          return false;
        }
        else
        {
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0');

          let codigo:ICodigosRenavam = { 
            coD_ORIGINAL: cod_original.value.toUpperCase(),
            tipO_COD: 1,
            coD_RENAVAM: cod_renavam.value,
            denoM_CURTA: cod_renavam.options[Number.parseInt(cod_renavam.value)].text,
            dT_INSERT: `${today.getFullYear()}-${mm}-${dd}`,
            useR_INSERT: this.username
          }

          this.renaService.setCodigoCorRenavam(codigo)
            .subscribe((response:IApiEnvelope) => {
              if (typeof(response.data) ==  'object') {
                Swal.fire({
                  icon: 'success',
                  title: 'Código inserido.',
                  toast: true,
                  position: 'bottom-end',
                  timer: 10000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                });

                this.GetCodigosRenavamPaginated(1);
              }  else if (typeof(response.data) ==  'string') {
                Swal.fire({
                  icon: 'error',
                  title: response.data,
                  toast: true,
                  position: 'bottom-end',
                  timer: 10000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                })
              }
            });

            return true;
          }
      }
    });
  }

  onTableChange(tipo_cod:number) {
    let filtros = this.filtros;
    filtros.paginationValue = '1';
    (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
    this.GetCodigosRenavamPaginated(tipo_cod);
  }

  GetCodigosRenavamPaginated(tipo_cod:number){
    this.renaService.getCodigosRenavamPaginated(tipo_cod, this.filtros)
    .subscribe(response => {
      this.tableNum = tipo_cod;
      this.tableData = response.data;
      this.tablePages = response.totalPages;
      
      response.data.forEach(item => {
        item.denoM_CURTA = item.denoM_CURTA == null ? "-" : item.denoM_CURTA;
        //@ts-ignore
        delete item.tipO_COD;
        delete item.useR_INSERT;
        delete item.dT_INSERT;
        delete item.useR_UPDATE;
        delete item.dT_UPDATE;
      });
    })
  }
}
