import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TegelModule } from '@scania/tegel-angular';
import { HeaderComponent } from './component/header/header.component';
import { HomeComponent } from './component/home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { RenaveComponent } from './component/menu/renave/renave.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { AcessosUsuariosComponent } from './component/menu/admin/acessos-usuarios/acessos-usuarios.component';
import { SolicitacaoEnvioComponent } from './component/menu/renavam/solicitacao-envio/solicitacao-envio.component';
import { MarcaModeloComponent } from './component/menu/renavam/marca-modelo/marca-modelo.component';
import { NotificacaoComponent } from './component/menu/renavam/notificacao/notificacao.component';
import { CodigosComponent } from './component/menu/renavam/codigos/codigos.component';
import { RetornoPreCadComponent } from './component/menu/renavam/retorno-precad/retorno-precad.component';
import { SpinnerInterceptor } from './service/spinner-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    RenaveComponent,
    AcessosUsuariosComponent,
    SolicitacaoEnvioComponent,
    MarcaModeloComponent,
    NotificacaoComponent,
    CodigosComponent,
    RetornoPreCadComponent
  ],
  imports: [
    BrowserModule,
    TegelModule,
    AppRoutingModule,
    HttpClientModule,
    [SweetAlert2Module.forRoot()],
    [SweetAlert2Module],
    [SweetAlert2Module.forChild({/* options */}),
    ],
  ],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptor, 
      multi: true 
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: SpinnerInterceptor, 
      multi: true 
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add the Schema
})
export class AppModule {}
