import { Component, OnInit } from '@angular/core';
import { IPaginatedFilters, IRetornoEnvio } from 'src/app/auth/interfaces';
import { RenaService } from 'src/app/service/rena.service';

@Component({
  selector: 'app-retorno-precad',
  templateUrl: './retorno-precad.component.html',
  styleUrls: ['./retorno-precad.component.css']
})

export class RetornoPreCadComponent implements OnInit {
  public tableData:IRetornoEnvio[] = [];
  private filtros:IPaginatedFilters = { paginationValue: '1', paginationSize: '10', columnKey: '', sortingDirection: '', query: ''};
  public tablePages:number = 1;

  constructor(private renaService: RenaService) {
  }

  ngOnInit() {
    this.getRetornoEnvioPaginated(this.filtros);
  }

  onPaginationEvent(event: any) {
    let filtros = this.filtros;
    filtros.paginationValue = event.detail.paginationValue;
    this.getRetornoEnvioPaginated(filtros);
  }

  onSortEvent(event: any) {
    let filtros = this.filtros;
    filtros.columnKey = event.detail.columnKey;
    filtros.paginationValue = '1';
    (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
    filtros.sortingDirection = event.detail.sortingDirection;
    this.getRetornoEnvioPaginated(filtros);
  }

  onFilterEvent(event: any) {
    let filtros = this.filtros;
    filtros.query = event.detail.query;
    filtros.paginationValue = '1';
    (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
    this.getRetornoEnvioPaginated(filtros);
  }

  getRetornoEnvioPaginated(filtros: IPaginatedFilters){
    this.renaService.getRetornoPreCadPaginated(filtros)
    .subscribe(response => {
      this.tableData = response.data;
      this.tablePages = response.totalPages;
    })
  }
}
