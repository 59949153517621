import { Component, OnInit } from '@angular/core';
import { IApiEnvelope, INotificacao } from 'src/app/auth/interfaces';
import { RenaService } from 'src/app/service/rena.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.css']
})

export class NotificacaoComponent implements OnInit {
  public table0Data:INotificacao[] = [];
  public table1Data:INotificacao[] = [];
  private username: string = (document.getElementById('username') as HTMLTdsHeaderDropdownListUserElement).imgAlt;
  public inlineTabIndex: number = 0;
  
  constructor(private renaService: RenaService) {
  }

  ngOnInit() {
    this.getRenavamNotificacaoTableData();
  }

  onTabChange(event: any): void {
    this.inlineTabIndex = event.detail.selectedTabIndex;
  }

  TableNotifyUserBtn(id_mensagem:number){
    Swal.fire({
      title: 'Notificar usuário',
      input: 'text',
      inputLabel: 'Digite um SSB válido',
      inputAttributes: {
        maxlength: '6'
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#16417f',
      allowOutsideClick: false,
      inputValidator: (username) => {
        if (!username || username.length != 6) {
          return 'Usuário inválido.'
        }
        return null;
      },
      preConfirm: (username) => {
        this.renaService.addUserNotify(username.toUpperCase(), id_mensagem, this.username)
        .subscribe((response:IApiEnvelope) => {
          if (response.success) {
            Swal.fire({
              icon: 'success',
              title: 'Notificação Incluída.',
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });

            this.getRenavamNotificacaoTableData();
          } else {
            Swal.fire({
              icon: 'error',
              title: response.error,
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            })
          }
        });
      }
    });
  }

  TableDontNotifyUserBtn(selectedUser:INotificacao, id_mensagem:number){
    Swal.fire({
      title: 'Remover notificação de usuário?',
      text: `${selectedUser.nome} não será mais notificado(a)`,
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#16417f',
      preConfirm: () => {
        this.renaService.removeUserNotify(selectedUser.registro, id_mensagem)
        .subscribe(() => {
          Swal.fire({
            icon: 'success',
            title: 'Notificação Removida.',
            toast: true,
            position: 'bottom-end',
            timer: 10000,
            timerProgressBar: true,
            showConfirmButton: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          this.getRenavamNotificacaoTableData();
        });
      }
    });
  }

  getRenavamNotificacaoTableData(){
    this.renaService.getNotificacaoTableData(5)
    .subscribe(response => {
      const sortedData = response.data.sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });
      this.table0Data = sortedData;
      
      sortedData.forEach(item => {
        item.email = item.email == null ? "-" : item.email;
        delete item.usuario;
        delete item.empresa;
      });
    })

    this.renaService.getNotificacaoTableData(6)
    .subscribe(response => {
      const sortedData = response.data.sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });
      this.table1Data = sortedData;
      
      sortedData.forEach(item => {
        item.email = item.email == null ? "-" : item.email;
        delete item.usuario;
        delete item.empresa;
      });
    })
  }
}
