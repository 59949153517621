<tds-header>
  <tds-header-title style="border-right: 1px solid var(--tds-header--basic-element-border);">
    <a class="tds-headline-04" [routerLink]="['Home']" style="text-decoration: none;color: white;">RENA</a>
  </tds-header-title>

  <tds-header-dropdown *ngIf="userIsAdmin$ | async">
    <span slot="label">ADMIN</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item>
        <a [routerLink]="['Admin/AcessosUsuarios']">Acessos de Usuários</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-dropdown *ngIf="userHasRenavamAccess$ | async" style="border-left: none;">
    <span slot="label">RENAVAM</span>
    <tds-header-dropdown-list>
      <tds-header-dropdown-list-item>
        <a [routerLink]="['Renavam/Codigos']">Códigos</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a [routerLink]="['Renavam/Notificacao']">Notificações</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a [routerLink]="['Renavam/MarcaModelo']">Classificação de Modelos</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a [routerLink]="['Renavam/SolicitacaoEnvio']">Solicitação de Envio - BIN</a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a [routerLink]="['Renavam/RetornoPreCadastro']">Retorno Pré-Cadastro</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-item *ngIf="userHasRenaveAccess$ | async" style="border-left: none;">
    <a [routerLink]="['Renave']">RENAVE</a>
  </tds-header-item>

  <tds-header-item (click)="authService.loginUser()" *ngIf="!isUserAuthenticated" style="position: absolute;right: 64px;">
    <a>Login</a>
  </tds-header-item>

  <tds-header-dropdown no-dropdown-icon *ngIf="isUserAuthenticated" style="position: absolute;right: 64px;">
    <img
      slot="icon"
      src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg"
      alt="User menu."
    />
    <tds-header-dropdown-list size="lg">
      <tds-header-dropdown-list-user id="username"
        imgAlt="{{ userData!.wam_user.global }}"
        header="{{ userData!.name }}"
        subheader="{{ userData!.email }}"
      ></tds-header-dropdown-list-user>
      <tds-header-dropdown-list-item (click)="this.userLogout()">
        <a>Logout</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-brand-symbol slot="end">
    <a aria-label="Scania - red gryphon on blue shield" href="https://scania.com"></a>
  </tds-header-brand-symbol>
</tds-header>
<tds-spinner *ngIf="loadingState" size="md" variant="standard" class="spinner-overlay"></tds-spinner>
