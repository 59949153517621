<div class="tds-container-fluid">
  <div class="tds-row">
    <h2 class="tds-headline-02">Notificações</h2>
  </div>
  <div class="tds-row">
    <tds-inline-tabs default-selected-index="0" mode-variant="primary" (tdsChange)="onTabChange($event)" style="margin-bottom: 1px;">
      <tds-inline-tab style="margin-left: 0;">
        <button>RENAVAM - Dados incompletos 1º emplacamento</button>
      </tds-inline-tab>
      <tds-inline-tab>
        <button>RENAVE - Erro saida de estoque</button>
      </tds-inline-tab>
    </tds-inline-tabs>
  </div>
  <div class="tds-row" *ngIf="inlineTabIndex == 0">
    <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width style="text-wrap: nowrap;">
      <tds-table-toolbar>
        <div slot="end" class="tds-u-flex tds-u-align-items-center tds-u-h-100 tds-u-gap1">
          <tds-button variant="secondary" size="sm" title="Incluir usuário" (click)="TableNotifyUserBtn(5)">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="plus"></tds-icon>
          </tds-button>
        </div>
      </tds-table-toolbar>
      <tds-table-header>
        <tds-header-cell cell-key='registro' cell-value='Registro'></tds-header-cell>
        <tds-header-cell cell-key='nome' cell-value='Nome'></tds-header-cell>
        <tds-header-cell cell-key='email' cell-value='Email'></tds-header-cell>
        <tds-header-cell cell-key="actions"></tds-header-cell>
      </tds-table-header>
      <tds-table-body>
          <tds-table-body-row *ngFor="let data of table0Data">
              <tds-body-cell cell-key="registro">{{data.registro}}</tds-body-cell>
              <tds-body-cell cell-key="nome">{{data.nome}}</tds-body-cell>
              <tds-body-cell cell-key="email">{{data.email}}</tds-body-cell>
              <tds-body-cell cell-key="actions">
                <div class="action-buttons">
                    <tds-icon id="edit" name="cross" size="20px" style="cursor: pointer;" (click)="TableDontNotifyUserBtn(data, 5)"></tds-icon>
                </div>
            </tds-body-cell>
          </tds-table-body-row>
      </tds-table-body>
      <tds-table-footer pages="1" pagination></tds-table-footer>
    </tds-table>
  </div>
  <div class="tds-row" *ngIf="inlineTabIndex == 1">
    <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width style="text-wrap: nowrap;">
      <tds-table-toolbar>
        <div slot="end" class="tds-u-flex tds-u-align-items-center tds-u-h-100 tds-u-gap1">
          <tds-button variant="secondary" size="sm" title="Incluir usuário" (click)="TableNotifyUserBtn(6)">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="plus"></tds-icon>
          </tds-button>
        </div>
      </tds-table-toolbar>
      <tds-table-header>
        <tds-header-cell cell-key='registro' cell-value='Registro'></tds-header-cell>
        <tds-header-cell cell-key='nome' cell-value='Nome'></tds-header-cell>
        <tds-header-cell cell-key='email' cell-value='Email'></tds-header-cell>
        <tds-header-cell cell-key="actions"></tds-header-cell>
      </tds-table-header>
      <tds-table-body>
        <tds-table-body-row>
          <tds-body-cell cell-key="registro"></tds-body-cell>
          <tds-body-cell cell-key="nome">ADMRENAVAM.BR@SCANIA.COM</tds-body-cell>
          <tds-body-cell cell-key="email">admrenavam.br@scania.com</tds-body-cell>
          <tds-body-cell cell-key="actions"></tds-body-cell>
        </tds-table-body-row>
        <tds-table-body-row>
          <tds-body-cell cell-key="registro"></tds-body-cell>
          <tds-body-cell cell-key="nome">FATURAMENTO.BR@SCANIA.COM</tds-body-cell>
          <tds-body-cell cell-key="email">faturamento.br@scania.com</tds-body-cell>
          <tds-body-cell cell-key="actions"></tds-body-cell>
        </tds-table-body-row>
        <tds-table-body-row *ngFor="let data of table1Data">
          <tds-body-cell cell-key="registro">{{data.registro}}</tds-body-cell>
          <tds-body-cell cell-key="nome">{{data.nome}}</tds-body-cell>
          <tds-body-cell cell-key="email">{{data.email}}</tds-body-cell>
          <tds-body-cell cell-key="actions">
            <div class="action-buttons">
                <tds-icon id="edit" name="cross" size="20px" style="cursor: pointer;" (click)="TableDontNotifyUserBtn(data, 6)"></tds-icon>
            </div>
          </tds-body-cell>
        </tds-table-body-row>
      </tds-table-body>
      <tds-table-footer pages="1" pagination></tds-table-footer>
    </tds-table>
  </div>
</div>
