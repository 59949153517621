import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IAcessosUsuarios, IApiEnvelope, IAplicacao, ICategoria, IChassi, ICodigosRenavam, ICombustivel, IMarca, IMarcaModelo, IMinhaReceita, INotificacao, IOperacaoRenave, IPaginated, IPaginatedFilters, IRenave, IRenaveEntry, IRenaveExitRequest, IRenaveMock, IRenavePDF, IRetornoEnvio, ISegmento, ISolicitacaoEnvio, ITracao, IViaCEP } from '../auth/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RenaService {

  constructor(private http: HttpClient) { }
    
    //#region ADMIN
    public getAdminAccessTableDataPaginated(filtros:IPaginatedFilters): Observable<IPaginated<IAcessosUsuarios[]>> {
      let params = new HttpParams()
        .set('columnKey', filtros.columnKey ?? '')
        .set('sortingDirection', filtros.sortingDirection ?? '')
        .set('query', filtros.query ?? '');
      
      if (filtros.paginationValue) {
        params = params.set('paginationValue', parseInt(filtros.paginationValue));
      }
      
      if (filtros.paginationSize) {
        params = params.set('paginationSize', parseInt(filtros.paginationSize));
      }

      return this.http.get<IPaginated<IAcessosUsuarios[]>>(`${environment.apiUrl}admin/table-data/paginated`, { params: params });
    }

    public addOrEditUser(cd_usuario:string, status:string = "") {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}admin/usuario?wam_user_xds=${cd_usuario}${status}`, null);
    }

    public editUserRoles(cd_usuario:string, roleId:number) {
      return this.http.post(`${environment.apiUrl}admin/usuario/acessos?wam_user_xds=${cd_usuario}&id_role=${roleId}`, null);
    }
    //#endregion

    //#region RENAVE
    public getViaCEP(cep:string) {
      return this.http.get<IViaCEP>(`https://viacep.com.br/ws/${cep}/json`);
    }

    public getMinhaReceita(cnpj:string) {
      return this.http.get<IMinhaReceita>(`https://minhareceita.org/${cnpj}`);
    }

    public getRenaveTableDataPaginated(estado:number, filtros:IPaginatedFilters): Observable<IPaginated<IRenave[]>> {
      let params = new HttpParams()
        .set('estado', estado)
        .set('columnKey', filtros.columnKey ?? '')
        .set('sortingDirection', filtros.sortingDirection ?? '')
        .set('query', filtros.query ?? '');
      
      if (filtros.paginationValue) {
        params = params.set('paginationValue', parseInt(filtros.paginationValue));
      }
      
      if (filtros.paginationSize) {
        params = params.set('paginationSize', parseInt(filtros.paginationSize));
      }

      return this.http.get<IPaginated<IRenave[]>>(`${environment.apiUrl}renave/table-data/paginated`, { params: params });
    }

    public addChassiMock(bodyData:any, vehicleType:boolean, cd_usuario:string) {
      return this.http.post<IApiEnvelope<IRenaveMock>>(`${environment.apiUrl}renave/mock?veiculoInacabado=${vehicleType}&username=${cd_usuario}`, bodyData);
    }

    public entry(bodyData:IRenaveEntry, vehicleType:boolean, cd_usuario:string) {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renave/entrada?veiculoAcabado=${vehicleType}&username=${cd_usuario}`, bodyData);
    }

    public entryCancel(idEstoque:number, vehicleType:boolean, cd_usuario:string) {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renave/cancelar-entrada?idEstoque=${idEstoque}&veiculoAcabado=${vehicleType}&username=${cd_usuario}`, null);
    }

    public exit(bodyData:IRenaveExitRequest, veiculoAcabado:string, chassi:string, cd_usuario:string) {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renave/saida-${veiculoAcabado}?chassi=${chassi}&username=${cd_usuario}`, bodyData);
    }

    public exitCancel(veiculoAcabado:string, idEstoque:number, vehicleType:boolean, cd_usuario:string) {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renave/cancelar-saida-${veiculoAcabado}?idEstoque=${idEstoque}&veiculoAcabado=${vehicleType}&username=${cd_usuario}`, null);
    }

    public getPDFData(requestSuffix:string) {
        return this.http.get<IApiEnvelope<IRenavePDF>>(`${environment.apiUrl}renave/${requestSuffix}`);
    }

    public getOperacaoRenaveData(chassi:string) {
        return this.http.get<IOperacaoRenave[]>(`${environment.apiUrl}renave/operacao/dados?chassi=${chassi}`);
    }
    //#endregion
    
    //#region RENAVAM
    public getSolicitacaoEnvioLoteZeroPaginated(filtros:IPaginatedFilters): Observable<IPaginated<ISolicitacaoEnvio[]>> {
      let params = new HttpParams()
        .set('columnKey', filtros.columnKey ?? '')
        .set('sortingDirection', filtros.sortingDirection ?? '')
        .set('query', filtros.query ?? '');
      
      if (filtros.paginationValue) {
        params = params.set('paginationValue', parseInt(filtros.paginationValue));
      }
      
      if (filtros.paginationSize) {
        params = params.set('paginationSize', parseInt(filtros.paginationSize));
      }

      return this.http.get<IPaginated<ISolicitacaoEnvio[]>>(`${environment.apiUrl}renavam/solic-env/lote-zero/paginated`, { params: params });
    }

    public getSolicitacaoEnvioPaginated(filtros:IPaginatedFilters): Observable<IPaginated<ISolicitacaoEnvio[]>> {
      let params = new HttpParams()
        .set('columnKey', filtros.columnKey ?? '')
        .set('sortingDirection', filtros.sortingDirection ?? '')
        .set('query', filtros.query ?? '');
      
      if (filtros.paginationValue) {
        params = params.set('paginationValue', parseInt(filtros.paginationValue));
      }
      
      if (filtros.paginationSize) {
        params = params.set('paginationSize', parseInt(filtros.paginationSize));
      }

      return this.http.get<IPaginated<ISolicitacaoEnvio[]>>(`${environment.apiUrl}renavam/solic-env/paginated`, { params: params });
    }

    public getCodigosRenavamPaginated(tipo_cod:number, filtros:IPaginatedFilters): Observable<IPaginated<ICodigosRenavam[]>> {
      let params = new HttpParams()
        .set('tipo_cod', tipo_cod)
        .set('columnKey', filtros.columnKey ?? '')
        .set('sortingDirection', filtros.sortingDirection ?? '')
        .set('query', filtros.query ?? '');
      
      if (filtros.paginationValue) {
        params = params.set('paginationValue', parseInt(filtros.paginationValue));
      }
      
      if (filtros.paginationSize) {
        params = params.set('paginationSize', parseInt(filtros.paginationSize));
      }

      return this.http.get<IPaginated<ICodigosRenavam[]>>(`${environment.apiUrl}renavam/codigos/paginated`, { params: params });
    }

    public setCodigoCorRenavam(bodyData:ICodigosRenavam) {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renavam/codigos/cor`, bodyData);
    }

    public solicitaEnvio(bodyData:any) {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renavam/solic-env`, bodyData);
    }

    public removeSolicitacaoEnvio(bodyData:any) {
      return this.http.post(`${environment.apiUrl}renavam/rm-solic-env`, bodyData, { observe: 'response' });
    }

    public sendQuest() {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renavam/quest`, null);
    }

    public uploadExcel(formData:any) {
      return this.http.post(`${environment.apiUrl}renavam/marca-modelo/excel/upload`, formData);
    }

    public updateExcel(formData:any) {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renavam/marca-modelo/excel/update`, formData);
    }

    public downloadExcel() {
      return this.http.get(`${environment.apiUrl}renavam/marca-modelo/excel/download`, {responseType: 'blob'});
    }

    public downloadBaseExcel() {
      return this.http.get('https://rena-marcamodelo-update-base-file.s3.sa-east-1.amazonaws.com/MarcaModelo_Update.xlsm', {responseType: 'blob'});
    }

    public getMarcaModeloTableDataPaginated(filtros:IPaginatedFilters): Observable<IPaginated<IMarcaModelo[]>> {
      let params = new HttpParams()
        .set('columnKey', filtros.columnKey ?? '')
        .set('sortingDirection', filtros.sortingDirection ?? '')
        .set('query', filtros.query ?? '');
      
      if (filtros.paginationValue) {
        params = params.set('paginationValue', parseInt(filtros.paginationValue));
      }
      
      if (filtros.paginationSize) {
        params = params.set('paginationSize', parseInt(filtros.paginationSize));
      }

      return this.http.get<IPaginated<IMarcaModelo[]>>(`${environment.apiUrl}renavam/marca-modelo/table-data/paginated`, { params: params });
    }
    
    public getMarcaModelo(suffixData:string = "") {
      return this.http.get<IApiEnvelope<IMarcaModelo[]>>(`${environment.apiUrl}renavam/marca-modelo${suffixData}`);
    }
    
    public postMarcaModelo(bodyData:any) {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renavam/marca-modelo`, bodyData);
    }
    
    public getMarca() {
      return this.http.get<IApiEnvelope<IMarca[]>>(`${environment.apiUrl}renavam/marca`);
    }
    
    public getAplicacao() {
      return this.http.get<IApiEnvelope<IAplicacao[]>>(`${environment.apiUrl}renavam/aplicacao`);
    }
    
    public getCategoria() {
      return this.http.get<IApiEnvelope<ICategoria[]>>(`${environment.apiUrl}renavam/categoria`);
    }
    
    public getCombustivel() {
      return this.http.get<IApiEnvelope<ICombustivel[]>>(`${environment.apiUrl}renavam/combustivel`);
    }
    
    public getChassi() {
      return this.http.get<IApiEnvelope<IChassi[]>>(`${environment.apiUrl}renavam/chassi`);
    }
    
    public getSegmento() {
      return this.http.get<IApiEnvelope<ISegmento[]>>(`${environment.apiUrl}renavam/segmento`);
    }
    
    public getTracao() {
      return this.http.get<IApiEnvelope<ITracao[]>>(`${environment.apiUrl}renavam/tracao`);
    }
    
    public getNotificacaoTableData(id_mensagem:number) {
      return this.http.get<IApiEnvelope<INotificacao[]>>(`${environment.apiUrl}renavam/notificacao?id_mensagem=${id_mensagem}`);
    }

    public addUserNotify(cd_usuario:string, id_mensagem:number, user_insert:string) {
      return this.http.post<IApiEnvelope>(`${environment.apiUrl}renavam/notificacao?wam_user_xds=${cd_usuario}&id_mensagem=${id_mensagem}&user_insert=${user_insert}`, null);
    }

    public removeUserNotify(registro:number, id_mensagem:number) {
      return this.http.post(`${environment.apiUrl}renavam/notificacao/rm?registro=${registro}&id_mensagem=${id_mensagem}`, null);
    }

    public getRetornoPreCadPaginated(filtros:IPaginatedFilters): Observable<IPaginated<IRetornoEnvio[]>> {
      let params = new HttpParams()
        .set('columnKey', filtros.columnKey ?? '')
        .set('sortingDirection', filtros.sortingDirection ?? '')
        .set('query', filtros.query ?? '');
      
      if (filtros.paginationValue) {
        params = params.set('paginationValue', parseInt(filtros.paginationValue));
      }
      
      if (filtros.paginationSize) {
        params = params.set('paginationSize', parseInt(filtros.paginationSize));
      }

      return this.http.get<IPaginated<IRetornoEnvio[]>>(`${environment.apiUrl}renavam/retorno-envio/paginated`, { params: params });
    }
    //#endregion
}
