import { Component, OnInit } from '@angular/core';
import { IAcessosUsuarios, IApiEnvelope, IPaginatedFilters } from 'src/app/auth/interfaces';
import { RenaService } from 'src/app/service/rena.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-acessos-usuarios',
  templateUrl: './acessos-usuarios.component.html',
  styleUrls: ['./acessos-usuarios.component.css']
})

export class AcessosUsuariosComponent implements OnInit {
  public tableData:IAcessosUsuarios[] = [];
  private filtros:IPaginatedFilters = { paginationValue: '1', paginationSize: '10', columnKey: '', sortingDirection: '', query: ''};
  public tablePages:number = 1;

  constructor(private renaService: RenaService) {
  }

  ngOnInit() {
    this.getAdminAccessTableDataPaginated(this.filtros);
  }
  
  onPaginationEvent(event: any) {
    let filtros = this.filtros;
    filtros.paginationValue = event.detail.paginationValue;
    this.getAdminAccessTableDataPaginated(filtros);
  }

  onSortEvent(event: any) {
    let filtros = this.filtros;
    filtros.columnKey = event.detail.columnKey;
    filtros.paginationValue = '1';
    (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
    filtros.sortingDirection = event.detail.sortingDirection;
    this.getAdminAccessTableDataPaginated(filtros);
  }

  onFilterEvent(event: any) {
    let filtros = this.filtros;
    filtros.query = event.detail.query;
    filtros.paginationValue = '1';
    (document.getElementById("tableFooter") as HTMLTdsTableFooterElement).paginationValue = 1;
    this.getAdminAccessTableDataPaginated(filtros);
  }

  TableAddUserBtn(){
    Swal.fire({
      title: 'Cadastrar usuário',
      input: 'text',
      inputLabel: 'Digite um SSB válido',
      inputAttributes: {
        maxlength: '6'
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#16417f',
      allowOutsideClick: false,
      inputValidator: (username) => {
        if (!username || username.length != 6) {
          return 'Usuário inválido.'
        }
        return null;
      },
      preConfirm: (username) => {
       this.renaService.addOrEditUser(username.toUpperCase())
        .subscribe((response:IApiEnvelope) => {
          if (response.success) {
            if (typeof(response.data) ==  'object') {
              Swal.fire({
                icon: 'success',
                title: 'Usuário cadastrado com sucesso.',
                toast: true,
                position: 'bottom-end',
                timer: 10000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
              });
  
              this.getAdminAccessTableDataPaginated(this.filtros);
            } else if (typeof(response.data) ==  'string') {
              Swal.fire({
                icon: 'error',
                title: response.data,
                toast: true,
                position: 'bottom-end',
                timer: 10000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
              })
            }
          }
        });
      }
    });
  }
  
  TableActionBtn(selectedUser:IAcessosUsuarios){
        Swal.fire({
          title: `${selectedUser.cd_usuario}: ${selectedUser.ds_nome.split(" ")[0]}`,
          html: `
          <div class="tds-container" style="padding: var(--tds-spacing-layout-32);text-align:left">
            <div class="tds-row">
              <div class="tds-checkbox-item tds-col-sm-6">
                <input class="tds-form-input" type="checkbox" id="user-status">
                <label class="tds-form-label" for="user-status">
                  Ativo
                </label>
              </div>
              <div class="tds-checkbox-item tds-col-sm-6">
                <input class="tds-form-input" type="checkbox" id="user-admin-role">
                <label class="tds-form-label" for="user-admin-role">
                  Admin Access
                </label>
              </div>
            </div>
            <div class="tds-row">
              <div class="tds-checkbox-item tds-col-sm-6">
                <input class="tds-form-input" type="checkbox" id="user-renavam-role">
                <label class="tds-form-label" for="user-renavam-role">
                  Renavam Access
                </label>
              </div>
              <div class="tds-checkbox-item tds-col-sm-6">
                <input class="tds-form-input" type="checkbox" id="user-renave-role">
                <label class="tds-form-label" for="user-renave-role">
                  Renave Access
                </label>
              </div>
            </div>
          </div>
          `,
          willOpen: () =>{
            if (selectedUser.fl_ativo == "Ativo") {
              (document.getElementById('user-status') as HTMLInputElement).checked = true;
            }
            if (selectedUser.admin_role == "X") {
              (document.getElementById('user-admin-role') as HTMLInputElement).checked = true;
            }
            if (selectedUser.renavam_role == "X") {
              (document.getElementById('user-renavam-role') as HTMLInputElement).checked = true;
            }
            if (selectedUser.renave_role == "X") {
              (document.getElementById('user-renave-role') as HTMLInputElement).checked = true;
            }
          },
          didOpen: () => {
            let status = (document.getElementById('user-status') as HTMLInputElement);
            let adminRole = (document.getElementById('user-admin-role') as HTMLInputElement);
            let renavamRole = (document.getElementById('user-renavam-role') as HTMLInputElement);
            let renaveRole = (document.getElementById('user-renave-role') as HTMLInputElement);
            
            status.addEventListener('change', () => {
              this.renaService.addOrEditUser(selectedUser.cd_usuario, `&status=${status.checked}`)
              .subscribe();
            });

            let that = this;
            function handleRoleChange(roleNumber:number) {
              that.renaService.editUserRoles(selectedUser.cd_usuario, roleNumber)
                .subscribe();
            }

            adminRole.addEventListener('change', () => {
              handleRoleChange(1);
            });

            renavamRole.addEventListener('change', () => {
              handleRoleChange(2);
            });
            
            renaveRole.addEventListener('change', () => {
              handleRoleChange(3);
            });
          },
          allowOutsideClick: false,
          showCancelButton: true,
          allowEnterKey: false,
          width: 'sm',
          confirmButtonColor: '#16417f',
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: 'success',
              title: 'Acessos alterados com sucesso.',
              toast: true,
              position: 'bottom-end',
              timer: 10000,
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            })
          }
          this.getAdminAccessTableDataPaginated(this.filtros);
        });
  }

  getAdminAccessTableDataPaginated(filtros:IPaginatedFilters){
    this.renaService.getAdminAccessTableDataPaginated(filtros)
    .subscribe(response => {
      this.tableData = response.data;
      this.tablePages = response.totalPages;
    })
  }
}
