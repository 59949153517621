<div class="tds-container-fluid">
  <div class="tds-row">
    <h2 class="tds-headline-02">Acessos</h2>
  </div>
  <div class="tds-row">
    <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width (tdsFilter)="onFilterEvent($event)" (tdsSort)="onSortEvent($event)">
      <tds-table-toolbar filter>
        <div slot="end" class="tds-u-flex tds-u-align-items-center tds-u-h-100 tds-u-gap1" style="margin-left: 8px;">
          <tds-button variant="secondary" size="sm" title="Adicionar usuário" (click)="TableAddUserBtn()">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="plus"></tds-icon>
          </tds-button>
        </div>
      </tds-table-toolbar>
      <tds-table-header>
        <tds-header-cell cell-key='cd_usuario' cell-value='Usuário' sortable></tds-header-cell>
        <tds-header-cell cell-key='ds_nome' cell-value='Nome' sortable></tds-header-cell>
        <tds-header-cell cell-key='ds_email' cell-value='Email' sortable></tds-header-cell>
        <tds-header-cell cell-key='fl_ativo' cell-value='Status'></tds-header-cell>
        <tds-header-cell cell-key='dt_ultimo_login' cell-value='Data Ultimo Login' sortable></tds-header-cell>
        <tds-header-cell cell-key='admin_role' cell-value='Admin Access'></tds-header-cell>
        <tds-header-cell cell-key='renavam_role' cell-value='Renavam Access'></tds-header-cell>
        <tds-header-cell cell-key='renave_role' cell-value='Renave Access'></tds-header-cell>
        <tds-header-cell cell-key="actions"></tds-header-cell>
      </tds-table-header>
      <tds-table-body>
          <tds-table-body-row *ngFor="let data of tableData">
              <tds-body-cell cell-key="cd_usuario">{{data.cd_usuario}}</tds-body-cell>
              <tds-body-cell cell-key="ds_nome">{{data.ds_nome}}</tds-body-cell>
              <tds-body-cell cell-key="ds_email">{{data.ds_email}}</tds-body-cell>
              <tds-body-cell cell-key="fl_ativo">{{data.fl_ativo}}</tds-body-cell>
              <tds-body-cell cell-key="dt_ultimo_login">{{data.dt_ultimo_login}}</tds-body-cell>
              <tds-body-cell cell-key="admin_role">{{data.admin_role}}</tds-body-cell>
              <tds-body-cell cell-key="renavam_role">{{data.renavam_role}}</tds-body-cell>
              <tds-body-cell cell-key="renave_role">{{data.renave_role}}</tds-body-cell>
              <tds-body-cell cell-key="actions">
                  <div class="action-buttons">
                      <tds-icon id="edit" name="settings" size="20px" style="cursor: pointer;" (click)="TableActionBtn(data)"></tds-icon>
                  </div>
              </tds-body-cell>
          </tds-table-body-row>
      </tds-table-body>
      <tds-table-footer id="tableFooter" pages="{{tablePages}}" pagination (tdsPagination)="onPaginationEvent($event)"></tds-table-footer>
    </tds-table>
  </div>
</div>
