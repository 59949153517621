import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from 'src/app/service/shared.service';
import { AuthService } from './auth.service';
import { Observable, Subject, map, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard {

    constructor(
        private authService: AuthService,
        private shared: SharedService,
        private router: Router) { }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const canActivateSubject = new Subject<boolean>();
        this.authService.checkAuthentication().pipe(
            take(1),
            map(() => this.authService.getUserData())
        ).subscribe((user) => {
            if (user) {
                this.authService.getUserRoles(user.wam_user.global).subscribe(user => {
                    if (typeof(user.data) === "string") {
                        this.router.navigate(['/Home']);
                        this.shared.errorAlert(String(user.data).split("|")[0], String(user.data).split("|")[1]);
                        canActivateSubject.next(false);
                    }
                    else
                    if (!user.data.role.some(role => role.ds_role == route.routeConfig?.path?.split('/')[0])) {
                        this.router.navigate(['/Home']);
                        this.shared.errorAlert('Acesso negado.', 'Por favor, entre em contato com um administrador.');
                        canActivateSubject.next(false);
                    }
                    canActivateSubject.next(true);
                });
            }
            else {
                this.router.navigate(['/Home']);
                this.shared.errorAlert('Acesso negado.', 'Por favor, realize o login.');
                canActivateSubject.next(false);
            }
        });
        return canActivateSubject.asObservable().pipe(take(1));
    }
}
