import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, switchMap, tap, throwError } from 'rxjs';
import { HeaderService } from '../service/header.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private headerService: HeaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('viacep') !== -1 || request.url.indexOf('minhareceita') !== -1 || request.url.indexOf('rena-marcamodelo-update-base-file') !== -1) {
      const modifiedReq = request.clone({
        headers: request.headers.delete('Authorization')
      });
      
      return next.handle(modifiedReq);
    }

    if (
      request.url != this.authService.getTokenUri() &&
      this.authService.getTokenValue() ) 
    {
      request = this.setAuthorizationHeader(
          request,
          this.authService.getTokenValue()
      );
    }

    // send cloned request with header to the next handler.
    return next.handle(request).pipe(
      catchError((responseError: HttpErrorResponse) => {
          if (
              responseError.status === 401 ||
              responseError.status === 403
          ) {
              return this.refreshToken().pipe(
                  switchMap((token) => {     
                      request = this.setAuthorizationHeader(
                          request,
                          token
                      );      
                      
                      return next.handle(request);
                  }),
                  catchError((ex) => {
                      if (ex.error.error_description === "Token is not active")
                      {
                          this.headerService.clearMenus();
                          this.authService.userLogout(); 
                          return new Observable<never>();
                      }
                      else
                          return throwError(() => ex);
                  })                                               
              );
          }

          return throwError(() => responseError);
      })
    );
  }

  private setAuthorizationHeader( request: HttpRequest<any>, 
                                  token: string ): HttpRequest<any> {
    return request.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`
        },
    });
  }

  private refreshToken(): Observable<string> {
    return this.authService
        .getRefreshToken(this.authService.getResfreshTokenValue())
        .pipe(
            tap((result) => this.authService.setToken(result)),
            map((result) => result.access_token)
        );
  }
}