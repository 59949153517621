import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { LoginCallbackComponent } from './component/login-callback/login-callback.component';
import { AcessosUsuariosComponent } from './component/menu/admin/acessos-usuarios/acessos-usuarios.component';
import { SolicitacaoEnvioComponent } from './component/menu/renavam/solicitacao-envio/solicitacao-envio.component';
import { RenaveComponent } from './component/menu/renave/renave.component';
import { AuthGuard } from './auth/auth.guard';
import { CodigosComponent } from './component/menu/renavam/codigos/codigos.component';
import { NotificacaoComponent } from './component/menu/renavam/notificacao/notificacao.component';
import { MarcaModeloComponent } from './component/menu/renavam/marca-modelo/marca-modelo.component';
import { RetornoPreCadComponent } from './component/menu/renavam/retorno-precad/retorno-precad.component';

const routes: Routes = [
  { path: '', redirectTo: '/Home', pathMatch: 'full' },
  { path: 'Home', component: HomeComponent, title: 'RENA' },
  { path: 'login-callback', component: LoginCallbackComponent, title: 'login-callback' },
  { path: 'Admin/AcessosUsuarios', component: AcessosUsuariosComponent, title: 'ADMIN - Usuários', canActivate: [AuthGuard] },
  { path: 'Renavam/Codigos', component: CodigosComponent, title: 'RENAVAM - Códigos', canActivate: [AuthGuard] },
  { path: 'Renavam/MarcaModelo', component: MarcaModeloComponent, title: 'RENAVAM - Modelos', canActivate: [AuthGuard] },
  { path: 'Renavam/Notificacao', component: NotificacaoComponent, title: 'RENAVAM - Notificações', canActivate: [AuthGuard] },
  { path: 'Renavam/RetornoPreCadastro', component: RetornoPreCadComponent, title: 'RENAVAM - Retorno Pré-Cadastro', canActivate: [AuthGuard] },
  { path: 'Renavam/SolicitacaoEnvio', component: SolicitacaoEnvioComponent, title: 'RENAVAM - Solicitação de Envio', canActivate: [AuthGuard] },
  { path: 'Renave', component: RenaveComponent, title: 'RENAVE', canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
