import { DinamicConfig, IUser, IUserRole, TokenResponse } from './interfaces';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, tap, take, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
    private checkAuthentication$: BehaviorSubject<boolean> =
      new BehaviorSubject(this.checkToken(environment.auth_cookie));

  constructor(
      private http: HttpClient,
      private router: Router
  ) {}

  checkToken(cookieName: string): boolean {
    return localStorage.getItem(cookieName) != null ? true : false;
  }

  checkAuthentication(): Observable<boolean> {
    return this.checkAuthentication$.asObservable();
  }

  checkAuthenticationCallback(): Observable<boolean> {
    return of(window.location.href.indexOf('/callback') > -1);
  }

  redirectLoginPage(): void {
    window.location.href = this.replaceEnvironmentUri(environment.authUri);
  }

  replaceEnvironmentUri(uri:string) {
    return uri.replace('{client_id}',this.getClientId())
      .replace('{login_type}',this.getLoginType());
  }

  loginUser() {
    return this.http.get<DinamicConfig>(environment.internalAuthorizerUri).pipe(
      catchError((responseError: HttpErrorResponse) => {
        return this.http.get<DinamicConfig>(environment.externalAuthorizerUri);
      }))
      .subscribe(data => {
        localStorage.setItem(environment.tokenUri_key, data.tokenUri);
        localStorage.setItem(environment.refreshTokenUri_key, data.refreshTokenUri);
        localStorage.setItem(environment.clientId_key, data.clientId);
        localStorage.setItem(environment.loginType_key, data.loginType);
        this.redirectLoginPage();
      });         
  }

  setToken(tokenResponse: TokenResponse): void {
    localStorage.setItem(environment.auth_cookie, tokenResponse.access_token);
    localStorage.setItem(environment.refresh_cookie, tokenResponse.refresh_token);
    this.checkAuthentication$.next(this.checkToken(environment.auth_cookie));
  }

  getTokenUri():string {
    return localStorage.getItem(environment.tokenUri_key) || '';
  }

  getRefreshTokenUri():string {
    return localStorage.getItem(environment.refreshTokenUri_key) || '';
  }

  getClientId():string {
    return localStorage.getItem(environment.clientId_key) || '';
  }

  getLoginType():string {
    return localStorage.getItem(environment.loginType_key) || '';
  }

  getToken(code: string): Observable<TokenResponse> {
    return this.http
      .post<TokenResponse>(this.getTokenUri(), {
          Code: code
      })
      .pipe(take(1));
  }

  getTokenValue(): string {
    return localStorage.getItem(environment.auth_cookie) || '';
  }

  getResfreshTokenValue(): string {
    return localStorage.getItem(environment.refresh_cookie) || '';
  }

  getUserData(): IUser | null {
    if (this.getTokenValue()) {
        const tokenDecoded = <IUser>(
            jwt_decode(this.getTokenValue())
        );
        return tokenDecoded;
    }

    return null;
  }

  userLogout(): void {
    localStorage.clear();
    this.checkAuthentication$.next(this.checkToken(environment.auth_cookie));
    this.router.navigate(['/Home']);
  }

  getRefreshToken(refreshToken: string): Observable<TokenResponse> {
    return of(true).pipe(
      //tap(() => this.cookieService.remove(auth_cookie)),
      tap(() => localStorage.removeItem(environment.auth_cookie)),
      // tap(() => this.checkAuthentication$.next(this.checkToken(auth_cookie))),
      switchMap(() =>
          this.http.post<TokenResponse>(this.getRefreshTokenUri(), {
            RefreshToken: refreshToken,
          })
      ),
      take(1)
    );
  }

  getUserRoles(usuario?: string) {
    return this.http.get<IUserRole>(environment.apiUrl + 'auth?cd_usuario=' + usuario);
  }
}
