<div class="tds-container-fluid">
  <div class="tds-row">
    <h2 class="tds-headline-02">Solicitação de Envio - BIN</h2>
  </div>
  <div class="tds-row">
    <tds-inline-tabs default-selected-index=0 mode-variant="primary" (tdsChange)="onTabChange($event)" style="margin-bottom: 1px;">
      <tds-inline-tab style="margin-left: 0;">
        <button>Solicitações Ativas</button>
      </tds-inline-tab>
      <tds-inline-tab>
        <button>Histórico de Solicitações</button>
      </tds-inline-tab>
    </tds-inline-tabs>
  </div>
  <div class="tds-row" *ngIf="inlineTabIndex == 0">
    <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width (tdsFilter)="onFilterEvent($event)" (tdsSort)="onSortEvent($event)">
      <tds-table-toolbar filter>
        <div slot="end" class="tds-u-flex tds-u-align-items-center tds-u-h-100 tds-u-gap1" style="margin-left: 8px;">
          <tds-button variant="secondary" size="sm" title="Incluir solicitação" (click)="TableAddSolicitacaoBtn()">
            <tds-icon slot="icon" class="tds-btn-icon" size="18px" name="plus"></tds-icon>
          </tds-button>
        </div>
      </tds-table-toolbar>
      <tds-table-header>
        <tds-header-cell cell-key="iD_CHASSI" cell-value="Chassi" sortable></tds-header-cell>
        <tds-header-cell cell-key="dT_SOLIC" cell-value="Data de Solicitação" sortable></tds-header-cell>
        <tds-header-cell cell-key="tP_MANUT" cell-value="Tipo de Manutenção"></tds-header-cell>
        <tds-header-cell cell-key="lote" cell-value="Número de Lote"></tds-header-cell>
        <tds-header-cell cell-key="observ" cell-value="Observação"></tds-header-cell>
        <tds-header-cell cell-key="actions"></tds-header-cell>
    </tds-table-header>
    <tds-table-body>
        <tds-table-body-row *ngFor="let data of tableDataLoteZero">
          <tds-body-cell cell-key="iD_CHASSI">{{data.iD_CHASSI}}</tds-body-cell>
          <tds-body-cell cell-key="dT_SOLIC">{{data.dT_SOLIC}}</tds-body-cell>
          <tds-body-cell cell-key="tP_MANUT">{{data.tP_MANUT}}</tds-body-cell>
          <tds-body-cell cell-key="lote">{{data.lote}}</tds-body-cell>
          <tds-body-cell cell-key="observ">{{data.observ}}</tds-body-cell>
          <tds-body-cell cell-key="actions">
            <div class="action-buttons">
                <tds-icon id="edit" name="cross" size="20px" title="Remover solicitação" (click)="TableRemoveSolicitacaoBtn(data)" style="cursor: pointer;"></tds-icon>
            </div>
          </tds-body-cell>
        </tds-table-body-row>
      </tds-table-body>
      <tds-table-footer id="tablePagesLoteFooter" pages="{{tablePagesLoteZero}}" pagination (tdsPagination)="onPaginationEvent($event)"></tds-table-footer>
    </tds-table>
  </div>
  <div class="tds-row" *ngIf="inlineTabIndex == 1">
    <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width (tdsFilter)="onFilterEvent($event)" (tdsSort)="onSortEvent($event)">
      <tds-table-toolbar filter></tds-table-toolbar>
      <tds-table-header>
        <tds-header-cell cell-key="iD_CHASSI" cell-value="Chassi" sortable></tds-header-cell>
        <tds-header-cell cell-key="dT_SOLIC" cell-value="Data de Solicitação" sortable></tds-header-cell>
        <tds-header-cell cell-key="tP_MANUT" cell-value="Tipo de Manutenção"></tds-header-cell>
        <tds-header-cell cell-key="lote" cell-value="Número de Lote" sortable></tds-header-cell>
        <tds-header-cell cell-key="observ" cell-value="Observação"></tds-header-cell>
    </tds-table-header>
    <tds-table-body>
        <tds-table-body-row *ngFor="let data of tableData">
          <tds-body-cell cell-key="iD_CHASSI">{{data.iD_CHASSI}}</tds-body-cell>
          <tds-body-cell cell-key="dT_SOLIC">{{data.dT_SOLIC}}</tds-body-cell>
          <tds-body-cell cell-key="tP_MANUT">{{data.tP_MANUT}}</tds-body-cell>
          <tds-body-cell cell-key="lote">{{data.lote}}</tds-body-cell>
          <tds-body-cell cell-key="observ">{{data.observ}}</tds-body-cell>
        </tds-table-body-row>
      </tds-table-body>
      <tds-table-footer id="tableFooter" pages="{{tablePages}}" pagination (tdsPagination)="onPaginationEvent($event)"></tds-table-footer>
    </tds-table>
  </div>
</div>
