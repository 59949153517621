<div class="tds-container-fluid">
    <div class="tds-row">
        <h2 class="tds-headline-02">Retorno Pré-Cadastro</h2>
    </div>
    <div class="tds-row">
        <tds-table vertical-dividers="false" compact-design="false" responsive="true" no-min-width (tdsFilter)="onFilterEvent($event)" (tdsSort)="onSortEvent($event)">
            <tds-table-toolbar filter></tds-table-toolbar>
            <tds-table-header>
                <tds-header-cell cell-key='nu_chassi' cell-value='Chassi' sortable></tds-header-cell>
                <tds-header-cell cell-key='nr_lote' cell-value='Lote' sortable></tds-header-cell>
                <tds-header-cell cell-key='dt_retorno' cell-value='Data' sortable></tds-header-cell>
                <tds-header-cell cell-key='ds_mensagem' cell-value='Mensagem'></tds-header-cell>
            </tds-table-header>
            <tds-table-body>
                <tds-table-body-row *ngFor="let data of tableData">
                    <tds-body-cell cell-key="nu_chassi">{{data.nu_chassi}}</tds-body-cell>
                    <tds-body-cell cell-key="nr_lote">{{data.nr_lote}}</tds-body-cell>
                    <tds-body-cell cell-key="dt_retorno">{{data.dt_retorno}}</tds-body-cell>
                    <tds-body-cell cell-key="ds_mensagem" style="text-wrap: pretty;">{{data.ds_mensagem}}</tds-body-cell>
                </tds-table-body-row>
            </tds-table-body>
            <tds-table-footer id="tableFooter" pages="{{tablePages}}" pagination (tdsPagination)="onPaginationEvent($event)"></tds-table-footer>
        </tds-table>
    </div>
</div>
