import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, filter, Observable, Subscription, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class HeaderService implements OnDestroy {
  private subscriptionList: Subscription[] = [];

  private isUserAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isUserAuthenticated: Observable<boolean> = this.isUserAuthenticated$.asObservable();
  private menusSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public menus$: Observable<string[]> = this.menusSubject.asObservable();

  constructor(public authService: AuthService) {
    this.subscriptionList.push(
      this.authService.checkAuthentication()
      .pipe(
          tap((value) => this.isUserAuthenticated$.next(value)),
          filter((value) => !!value)
      )
      .subscribe(),
    );
   }

  setMenus(menus: string[]) {
    this.menusSubject.next(menus);
  }

  clearMenus() {
    this.menusSubject.next([]);
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
  }
}
