import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  warningAlert(title:string, text:string = '', width:string = 'sm', footer:string = '') {
    switch (width)
    {
        case "sm":
            width = "30%";
            break;
        case "md":
            width = "45%";
            break;
        default:
            width = "60%";
            break;
    }

    Swal.fire({
      icon: 'warning',
      title: title,
      text: text,
      allowOutsideClick: false,
      allowEnterKey: false,
      width: width,
      confirmButtonColor: '#F1C21B',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  errorAlert(title:string, text:string = '', width:string = 'sm', footer:string = '') {
    switch (width)
    {
        case "sm":
            width = "30%";
            break;
        case "md":
            width = "45%";
            break;
        default:
            width = "60%";
            break;
    }
    
    Swal.fire({
      icon: 'error',
      title: title,
      text: text,
      allowOutsideClick: false,
      width: width,
      footer:
        footer != ''
          ? `<tds-accordion><tds-accordion-item header="Technical details" expand-icon-position="start" tabindex="1">${footer}</tds-accordion-item></tds-accordion>`
          : '',
      allowEnterKey: false,
      confirmButtonColor: '#FF2340',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  successAlert(title:string, text:string = '', width:string = 'sm', footer:string = '') {
    switch (width)
    {
        case "sm":
            width = "30%";
            break;
        case "md":
            width = "45%";
            break;
        default:
            width = "60%";
            break;
    }
    
    Swal.fire({
      icon: 'success',
      title: title,
      text: text,
      width: width,
      allowOutsideClick: false,
      allowEnterKey: false,
      confirmButtonColor: '#1DAB8B',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  warningOptionAlert(text:string, width:string = 'md', confirmButtonText:string = 'OK', cancelButtonText:string = 'Cancel') {
    switch (width)
    {
        case "sm":
            width = "30%";
            break;
        case "md":
            width = "45%";
            break;
        default:
            width = "60%";
            break;
    }

    return Swal.fire({
      icon: 'warning',
      title: 'Attention',
      text: text,
      width: width,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: cancelButtonText,
      allowEnterKey: false,
      confirmButtonColor: '#F1C21B',
      cancelButtonColor: '#CDD1DB',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }
}
